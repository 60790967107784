@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: Roboto, system-ui, sans-serif;
  }
}

.loadingIcon {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background: rgb(0, 0, 0, 0.5);
}

.loadingIcon img {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
  right: 0;
}
#root,
html,
body {
  height: 100%;
}

body {
  min-height: 100%;
}

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
  outline: none !important;
}
